import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
// components
import {
    FirstScreen,
    Mission,
    Team,
    SocialResponsibility,
} from '../components/AboutCompany'
// global
import { 
    FormOrder,
    Breadcrumbs
} from '../components/Global'
// content
import YAMLGlobal from "../content/ua/global.yaml"
import YAMLAbout from "../content/ua/about.yaml"

const AboutCompanyPage = ({location}) => {
  // Тут собрана вся необходимая инфа - передаешь пропсами вниз компонентам. Обращаться можно (data.mainInfo....)
  const data = useStaticQuery(graphql`
      query {
          seo: directusAboutSeo {
              title_ua
              description_ua
          }
          mainInfo: directusAboutMain {
              about_image {
                  localFile {
                      childImageSharp {
                          fluid(maxWidth: 1000) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
              about_title_ua
              about_description_ua
              title_ua
              mission_image {
                  localFile {
                      childImageSharp {
                          fluid(maxWidth: 520) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
              mission_description_ua
              goal_image {
                  localFile {
                      childImageSharp {
                          fluid(maxWidth: 520) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
              goal_description_ua
          }
          team: allDirectusTeam(sort: {fields: sort}) { #Тут можно лимит поставить если это необходимо
              edges {
                  node {
                      id
                      name_ua
                      label_ua
                      description_ua
                      image {
                          localFile {
                              childImageSharp {
                                  fluid(maxWidth: 300) {
                                      ...GatsbyImageSharpFluid
                                  }
                              }
                          }
                      }
                  }
              }
          }
          blog: allDirectusBlog(filter: {is_social: {eq: true}}, limit: 6) { #Лимит выбери как считаешь нужным
              edges {
                  node {
                      id
                      slug
                      title_ua
                      image {
                          localFile {
                              childImageSharp {
                                  fluid(maxWidth: 1000) {
                                      ...GatsbyImageSharpFluid
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `)

    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: '/about-company',
            title: YAMLGlobal.breadCrumbs.about
        }
    ]
    
    const aboutLocal = {
        langBase: "/ua",
        YAMLGlobal,
        YAMLContent: YAMLAbout
    }
    
    return(
        <Layout local={aboutLocal} location={location}>
            <SEO title={data.seo.title_ua} description={data.seo.description_ua} lang='ua' />
            <Breadcrumbs list={list} />
            <FirstScreen
                image={data.mainInfo.about_image.localFile ? data.mainInfo.about_image.localFile?.childImageSharp.fluid : ""}
                title={data.mainInfo.about_title_ua}
                description={data.mainInfo.about_description_ua}
            />
            <Mission
              title={data.mainInfo.title_ua}
              mission={{
                image: data.mainInfo.mission_image.localFile ? data.mainInfo.mission_image.localFile?.childImageSharp.fluid : "",
                description: data.mainInfo.mission_description_ua
              }}
              goal={{
                image: data.mainInfo.goal_image.localFile ? data.mainInfo.goal_image.localFile?.childImageSharp.fluid : "",
                description: data.mainInfo.goal_description_ua
              }}
            />
            <Team teammates={data.team.edges} />
            <SocialResponsibility articles={data.blog.edges} />
            <FormOrder/>
        </Layout>
    )
}

export default AboutCompanyPage;